<template>
  <div style="min-height:500px">
    <div class="row">
      <div class="col-8">
        <h3 class="page-header">Commissions Report</h3>
      </div>
      <div class="col-4 text-right">
        <finalize-modal :filterDate="filterDate" v-if="loading.commissionStatements === false && filterDate"/>
      </div>
    </div>

    <unmatched-alert v-if="false" :date="filterDate"></unmatched-alert>

    <v-card>
      <v-card-text>
        <div class="d-flex flex-wrap mt-5">
          <div class="flex-grow-1" style="max-width:700px;">
            <div class="d-flex flex-wrap">
              <div class="flex-grow-1 mr-3">
                Report For:
                <producer-select :filterDate="filterDate"
                                 v-if="loading.filterDate === false"></producer-select>
              </div>
              <div class="flex-grow-1">

                <v-menu
                  ref="menu"
                  v-model="monthMenu"
                  :close-on-content-click="false"
                  :return-value.sync="filterDate"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    Pay Period:
                    <v-text-field
                      v-model="filterDateFormatted"
                      :append-icon="icons.mdiCalendar"
                      @click:append="monthMenu = true"
                      readonly
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                      class="mt-1"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filterDate"
                    type="month"
                    no-title
                    scrollable
                    @change="$refs.menu.save(filterDate)"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="monthMenu = false"
                    >
                      Cancel
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </div>
            </div>
          </div>
          <div class="flex-grow-1 text-end pt-2">
            <v-btn
              :disabled="loading.download"
              color="secondary"
              class="mr-2"
              @click.prevent="download">
              <span v-if="loading.download === false">Download</span>
              <span v-else>Please wait...</span>
            </v-btn>
          </div>
        </div>
        <div class="mt-5">
          <v-data-table
            :headers="tableHeaders"
            :items="formattedTableData"
            :items-per-page="meta.per_page"
            :server-items-length="meta.total"
            :loading="loading.tableData"
            :options.sync="tableOptions"
            show-expand
            :footer-props="tableFooterOptions"
            @update:options="getTableData(tableOptions.page)"
            v-if="loading.filterDate === false"
            class="elevation-1"
          >

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <div class="d-md-flex py-5">
                  <div class="flex-grow-1 py-2"><strong>Sold:</strong> {{ item.sold_date }}</div>
                  <div class="flex-grow-1 py-2"><strong>Premium:</strong> {{ item.premium }}</div>
                  <div class="flex-grow-1 py-2"><strong>LOB:</strong> {{ item.lob }}</div>
                  <div class="flex-grow-1 py-2"><strong>Term:</strong> {{ item.terms }} Months</div>
                </div>
              </td>
            </template>


          </v-data-table>

        </div>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
import {
  mdiCalendar,
  mdiDomain,
  mdiFilter,
  mdiPound
} from "@mdi/js";
import moment from "moment";
import producerSelect from "@/pages/commissions/components/ProducerSelect";
import UnmatchedAlert from "@/pages/commissions/components/UnmatchedAlert";
import finalizeModal from "@/pages/commissions/components/FinalizeModal";
import commissionReportTableHeaders
  from "@/pages/commissions/commission-report/components/commissionReportTableHeaders.js";
import commissionReportTableFooterOptions
  from "@/pages/commissions/commission-report/components/commissionReportTableFooterOptions";
import commissionReportTableOptions
  from "@/pages/commissions/commission-report/components/commissionReportTableOptions";
import utilities from '@/config/globals/utilities';
import _ from "lodash";
import {mapGetters, mapMutations} from "vuex";
import * as EVENTS from "@/config/globals/trackingEvents";
import {DOWNLOAD_COMMISSIONS_REPORT} from "@/config/globals/trackingEvents";

export default {
  components: {
    UnmatchedAlert,
    producerSelect,
    finalizeModal
  },
  data() {
    return {
      icons: {
        mdiCalendar,
        mdiDomain,
        mdiFilter,
        mdiPound
      },
      monthMenu: false,
      tableHeaders: commissionReportTableHeaders,
      tableOptions: commissionReportTableOptions,
      tableFooterOptions: commissionReportTableFooterOptions,
      meta: {},
      filterDate: null,
      loading: {
        tableData: false,
        download: false,
        filterDate: true,
        commissionStatements: true
      },
    }
  },
  methods: {
    setInitialFilter() {
      if (this.$route.query.initialDate) {
        this.filterDate = this.$route.query.initialDate;
      } else {
        this.filterDate = moment().subtract(1, 'month').format('YYYY-MM');

        this.$router.push({
          name: 'commission-report',
          query: {initialDate: this.filterDate}
        });
      }

      this.loading.filterDate = false;

      return this;
    },
    getTableData(page = 1) {
      this.loading.commissionStatements = true;

      this.$api.commissions.index({
        page: page,
        page_size: this.tableOptions.itemsPerPage,
        filters: {
          date: this.filterDate
        }
      }).then(({data, meta}) => {
        this.setCommissionsReport(data);
        this.meta = meta;
        this.loading.commissionStatements = false;

        this.$router.push({
          name: 'commission-report',
          query: {
            initialDate: this.filterDate
          }
        }).catch(err => {
        });

        //this.$emit('resizeEmbedComponent');
      }).catch(error => {
        this.loading.commissionStatements = false;
      });

      return this;
    },
    download() {
      if (this.loading.download) {
        return;
      }

      this.loading.download = true;

      this.$api.commissions.export({
        filters: {
          date: this.filterDate
        }
      }).then(({data}) => {
        this.$tracking.event(EVENTS.DOWNLOAD_COMMISSIONS_REPORT,{
          report: 'Commissions Summary Report',
          date: this.filterDate,
        });

        window.location = data.file;
        setTimeout(() => {
          this.loading.download = false;
        }, 1500);
      }).catch(() => {
        this.loading.download = false;
      });
    },
    ...mapMutations({
      setCommissionsReport: 'setCommissionsReport'
    }),
  },
  mounted() {
    this.setInitialFilter();
  },
  watch: {
    filterDate() {
      this.getTableData();
    },
  },
  computed: {
    ...mapGetters({
      tableData: 'getCommissionsReport',
    }),
    formattedTableData() {
      return this.$_.map(this.tableData, (commission) => {
        return {
          id: commission.id,
          effective: this.$moment(commission.attributes.effective_date).format('MMM Do'),
          type: commission.attributes.reason.code,
          insured: commission.attributes.insured_name || "",
          policy_id: commission.attributes.policy_number,
          carrier: commission.attributes.carrier || "",
          agency_pay: utilities.formatCurrency(commission.attributes.ivans_amount),
          producer: this.$_.get(commission, 'related.user.data.attributes.name') || "",
          lob: _.startCase(commission.attributes.policy_type),
          premium: utilities.formatCurrency(commission.attributes.premium),
          sold_date: moment(commission.attributes.report_date).format('MMM Do'),
          terms: commission.attributes.terms
        }
      });
    },
    filterDateFormatted() {
      return this.$moment(this.filterDate).format('MMMM YYYY');
    },
    filterMonthName() {
      return this.$moment(this.filterDate).format('MMMM');
    }
  }
}
</script>

<style scoped>
.page-header {
  font-family: 'Roboto', serif;
  font-size: 32px;
  color: #422F2F;
  margin-bottom: 15px;
}

::v-deep .v-btn.v-size--default {
  min-width: auto;
}

::v-deep .v-alert__icon {
  top: 6px
}

::v-deep .v-data-table__expanded__row > .text-start {
  border-bottom: none !important;
}

::v-deep .v-data-table__expanded__row:hover {
  background: #fff !important;
}

::v-deep .text-start {
  height: 72px !important;
}
</style>
